<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      elevation="1"
      elevate-on-scroll
      height="80"
    >
      <land-img
        v-if="logoSrc"
        :src="logoSrc"
        contain
        max-width="208"
        :height="smAndDown ? 40 : 48"
        @click="onLogo"
      />

      <div class="ml-10">
        <v-tabs
          class="hidden-sm-and-down"
          optional
          background-color="transparent"
        >
          <v-tab
            v-for="(item, i) in items"
            :key="i"
            :to="{name: item.name}"
            :exact="item.name === 'Home'"
            :ripple="false"
            class="font-weight-bold text-subtitle-1"
            min-width="96"
            text
          >
            {{ item.title }}
          </v-tab>
        </v-tabs>
      </div>

      <v-spacer />
      <div class="co-flex-row co-items-center mr-6">
        <div
          v-if="!smAndDown"
          class="co-flex-row co-text-2xs co-text-gray-500 co-bg-gray-200 co-w160 px-6 co-py4 co-round-full mr-3"
          style="cursor: pointer;"
          @click="toSearch"
        >
          查询用户/作品/活动
        </div>
        <v-avatar
          v-else
          size="32"
          color="blue-grey lighten-5"
          style="cursor: pointer; margin-right: 6px;"
          @click="toSearch"
        >
          <v-icon
            color="blue-grey darken-4"
          >
            mdi-magnify
          </v-icon>
        </v-avatar>

        <div
          v-if="!logined"
          class="co-flex-row co-items-center"
        >
          <v-btn
            text
            class="mr-4"
            :height="btnHeight"
            @click="toLogin"
          >
            {{ btnLogin.title }}
          </v-btn>
          <!-- <v-btn
            outlined
            :height="btnHeight"
            @click="toRegister"
          >
            {{ btnRegister.title }}
          </v-btn> -->
        </div>
        <div
          v-else
          class="co-flex-row co-items-center"
        >
          <v-menu
            transition="scale-transition"
            offset-y
            open-on-hover
          >
            <template #activator="{ attrs, on }">
              <div
                class="co-flex-row co-items-center"
                style="cursor: pointer;"
                v-bind="attrs"
                v-on="on"
                @click="toMy"
              >
                <v-avatar
                  size="40"
                >
                  <v-img
                    :src="userInfo.avatar"
                  />
                </v-avatar>
                <div class="hidden-sm-and-down co-flex-col ml-2">
                  <span class="text-body-2">
                    {{ userInfo.username }}
                  </span>
                  <span
                    v-if="userInfo.organizationName"
                    class="text-caption co-text-gray-500"
                  >
                    {{ userInfo.organizationName }}
                  </span>
                </div>
              </div>
            </template>
            <v-list
              dense
            >
              <v-list-item
                v-for="(act, index) in menuItems"
                :key="`act_${index}`"
                link
                :disabled="act.disabled || false"
                @click="onAction(index, act)"
              >
                <v-list-item-icon v-if="act.icon">
                  <v-icon>{{ act.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ act.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn
            v-if="!smAndDown"
            class="mx-6"
            outlined
            :height="btnHeight"
            @click="toUpload"
          >
            {{ btnUpload.title }}
          </v-btn>
          <v-badge
            v-if="!smAndDown"
            dot
            overlap
            :color="btnMsg.color"
            :value="btnMsg.value"
          >
            <v-avatar
              size="40"
              color="blue-grey lighten-5"
              style="cursor: pointer;"
              @click="toMessage"
            >
              <v-icon
                color="blue-grey darken-4"
              >
                {{ btnMsg.icon }}
              </v-icon>
            </v-avatar>
          </v-badge>
        </div>
      </div>

      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      />
    </v-app-bar>

    <home-drawer
      v-model="drawer"
      :items="dwItems"
    />
    <dialog-alert ref="dlgAlert" />
  </div>
</template>

<script>
  /**
   * 暂时屏蔽圈子功能
   * {
        name: 'Circle',
        title: '圈子'
      }

    , {
        name: 'phone',
        icon: 'mdi-cellphone-check',
        text: '绑定手机号'
      }
      {
          name: 'person',
          icon: 'mdi-account-cog-outline',
          text: '个人基本资料'
        }, {
          name: 'hospital',
          icon: 'mdi-hospital-box-outline',
          text: '单位/学校信息'
        }, {
          name: 'password',
          icon: 'mdi-form-textbox-password',
          text: '修改密码'
        },
   */
  import api from '@/api/co.api'
  import app from '@/api/co.app'
  import user from '@/api/co.user'
  import socket from '@/api/co.socket'

  export default {
    name: 'HomeAppBar',
    components: {
      HomeDrawer: () => import('./Drawer'),
      DialogAlert: () => import('@/pages/sections/comm/DialogAlert.vue')
    },
    data () {
      return {
        drawer: false,
        logoSrc: '',
        items: [
          {
            name: 'Home',
            title: '首页'
          }, {
            name: 'Activity',
            title: '活动'
          }, {
            name: 'Hot',
            title: '热门'
          }, {
            name: 'About',
            title: '联系我们'
          }
        ],
        dwItems: [],
        btnHeight: 34,
        btnLogin: {
          name: 'Login',
          title: '登录/注册'
        },
        btnRegister: {
          name: 'Login',
          title: '注册'
        },
        btnUpload: {
          name: 'Upload',
          title: '上传'
        },
        btnMsg: {
          name: 'Messages',
          title: '我的消息',
          color: 'error',
          value: 0,
          icon: 'mdi-bell-outline'
        },
        split: {
          name: 'split-line',
          title: '-'
        },
        logined: false,
        userInfo: {},
        menuItems: [{
          name: 'account',
          icon: 'mdi-account-circle-outline',
          text: '账号信息设置'
        }, {
          name: 'logout',
          icon: 'mdi-logout',
          text: '退出账号'
        }],
        handlers: {
          account: this.toUserInfo,
          person: this.toUserInfo,
          hospital: this.toUserInfo,
          phone: this.toUserInfo,
          password: this.toUserInfo,
          logout: this.toLogout
        },
      }
    },
    computed: {
      mdAndDown () {
        return this.$vuetify.breakpoint.mdAndDown
      },
      smAndDown () {
        return this.$vuetify.breakpoint.smAndDown
      }
    },
    created () {
      this.logoSrc = this.getLogo()
      app.on(app.event.USER_LOGIN, this.onUserLogin)
      app.on(app.event.USER_LOGOUT, this.onUserLogout)
      app.on(app.event.USER_INFO_CHANGE, this.onUserInfoChange)
      app.on('appbar_reddot', this.onReddot)
      socket.main.bind({
        name: 'appbar_showdot',
        trigger: this.receivedMsg
      })
      this.getUserInfo()
    },
    methods: {
      onReddot (value) {
        this.btnMsg.value = value
      },
      showAlert (type, message) {
        const dlg = this.$refs.dlgAlert
        if (dlg) {
          dlg.show({
            type,
            message
          })
        }
      },
      receivedMsg (msg) {
        // console.log('receivedMsg msg: %o', msg)
        this.btnMsg.value += 1
      },
      toLogout (item) {
        const me = this
        const executing = function () {
          me.loading = true
        }
        const executed = function (res) {
          me.loading = false
          if (res.status) {
            app.emit(app.event.USER_LOGOUT)
            me.showAlert('success', '账号退出成功')
            // me.$notify({
            //   title: '成功提示',
            //   message: '账号退出成功！',
            //   type: 'success'
            // })
          } else {
            me.showAlert('error', '账号退出失败')
            // me.$notify({
            //   title: '错误提示',
            //   message: '账号退出失败！',
            //   type: 'error'
            // })
          }
        }

        user.login.exit({
          executing,
          executed
        })
      },
      toUserInfo (item) {
        const params = {
          name: item.name
        }

        api.page.navigate(this, 'info', params)
      },
      onAction (index, item) {
        const handler = this.handlers[item.name]
        if (typeof handler === 'function') {
          handler(item)
        }
      },
      onLogo (ev) {
        api.page.navigate(this, 'home')
      },
      configDrawerItems (logined = false) {
        this.dwItems = []
        for (const i in this.items) {
          const src = this.items[i]
          this.dwItems.push({ ...src })
        }

        this.dwItems.push(this.split)

        if (logined) {
          this.dwItems.push({
            name: 'My',
            title: '个人中心'
          }, {
            ...this.btnUpload
          }, {
            ...this.btnMsg
          })
        } else {
          this.dwItems.push({
            ...this.btnLogin
          }, {
            ...this.btnRegister
          })
        }
      },
      getUserInfo () {
        const me = this
        const callback = function (res) {
          if (res.status) {
            me.userInfo = Object.assign({}, res.data)
            me.setFirstItem('关注')

            me.logined = true
            me.configDrawerItems(me.logined)
            socket.main.connect({
              userId: me.userInfo.userId
            })
          } else {
            me.setFirstItem('首页')
            me.logined = false
            me.userInfo = {}
          }
        }

        user.login.isLogined(callback)
      },
      setFirstItem (title = '首页') {
        const src = this.items[0]
        const dst = Object.assign({}, src, { title })
        this.$set(this.items, 0, dst)
      },
      onUserLogin (ev) {
        // console.log('onUserLogin, ev: %o', ev)
        this.userInfo = Object.assign({}, ev)
        this.logined = true
        this.setFirstItem('关注')
        this.configDrawerItems(this.logined)

        api.page.navigate(this, 'home')
      },
      onUserLogout (ev) {
        // console.log('onUserLogout, ev: %o', ev)
        this.logined = false
        this.setFirstItem('首页')
        this.userInfo = {}
        this.configDrawerItems(this.logined)

        const currPath = this.$route.path
        const arr = currPath.split('/')
        // console.log('path: %o', this.$route.path)
        // console.log('arr: %o', arr)
        if (arr.length) {
          const temp = arr[arr.length - 1]
          if (temp) {
            api.page.navigate(this, 'home')
          } else {
            app.emit(app.event.HOME_CHECK_LOGIN)
          }
        } else {
          this.$router.go(0)
        }
      },
      onUserInfoChange (params) {
        for (const key in params) {
          this.$set(this.userInfo, key, params[key])
        }
      },
      getLogo () {
        /* logo 比例默认为 520 * 180 */
        const logo = 'https://oss.landcoo.com/yujian/1645327277803835392.png'
        return logo
      },
      toSearch (ev) {
        api.page.navigate(this, 'search')
      },
      toMy (ev) {
        const params = {
          ...this.userInfo
        }
        api.page.navigate(this, 'my', params)
      },
      toLogin (ev) {
        //  phone
        const params = {
          mode: 'qrcode'
        }
        const pgName = this.$route.name
        if (pgName === 'Login') {
          app.emit(app.event.LOGIN_PHONE, params)
        } else {
          api.page.navigate(this, 'login', params)
        }
      },
      toRegister (ev) {
        const params = {
          mode: 'qrcode'
        }

        const pgName = this.$route.name
        if (pgName === 'Login') {
          app.emit(app.event.LOGIN_QRCODE, params)
        } else {
          api.page.navigate(this, 'login', params)
        }
      },
      toUpload (ev) {
        api.page.navigate(this, 'upload')
      },
      toMessage (ev) {
        this.btnMsg.value = 0
        const params = {
          ...this.userInfo
        }
        api.page.navigate(this, 'messages', params)
      }
    }
  }
</script>

<style lang="sass">
  #home-app-bar
    .v-tabs-slider
      max-width: 24px
      margin: 0 auto

    .v-tab
      &::before
        display: none
</style>
